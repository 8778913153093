<template>
  <nav class="bg-white shadow-lg fixed w-full z-10">
    <div class="mx-auto max-w-7xl px-2">
      <div class="flex lg:h-[80px] h-16 items-center justify-between">
        <img
          class="block h-6 lg:h-8 ml-3 lg:ml-0 w-auto"
          src="@/assets/icons/lixil.png"
          alt="Lixil"
        />
        <!-- desktop -->
        <div class="hidden min-[1281px]:block flex items-center justify-center">
          <!-- <img class="block h-6 ml-3 w-auto" src="@/assets/icons/lixil.png" alt="Lixil" /> -->
          <div class="lg:ml-6">
            <ul v-if="checkRouter()" class="flex space-x-4">
              <li
                @click="top"
                :style="{ color: colorTop }"
                class="text-[#515C66] px-3 py-2 rounded-md text-md font-medium"
              >
                HOME
              </li>
              <li
                @click="customer_issues"
                :style="{ color: colorCustomerIssues }"
                class="text-[#515C66] px-3 py-2 rounded-md text-md font-medium"
              >
                お客様の課題
              </li>
              <li
                @click="solution"
                :style="{ color: colorSolution }"
                class="text-[#515C66] px-3 py-2 rounded-md text-md font-medium"
              >
                ソリューション
              </li>
              <li
                @click="compatible"
                :style="{ color: colorCompatible }"
                class="text-[#515C66] px-3 py-2 rounded-md text-md font-medium"
              >
                対応機種
              </li>
              <li
                @click="us_choose"
                :style="{ color: colorChoose }"
                class="text-[#515C66] px-3 py-2 rounded-md text-md font-medium"
              >
                選ばれる理由
              </li>
              <li
                @click="voice"
                :style="{ color: colorVoice }"
                class="text-[#515C66] px-3 py-2 rounded-md text-md font-medium"
              >
                ユーザーの声
              </li>
              <li
                @click="inquiry"
                :style="{ color: colorInquiry }"
                class="text-[#515C66] px-3 py-2 rounded-md text-md font-medium"
              >
                運用開始までの流れ
              </li>
            </ul>
            <ul class="flex space-x-4" v-else>
              <li class="text-[#515C66] px-3 py-2 rounded-md text-md font-medium">HOME</li>
              <li class="text-[#515C66] px-3 py-2 rounded-md text-md font-medium">お客様の課題</li>
              <li class="text-[#515C66] px-3 py-2 rounded-md text-md font-medium">
                ソリューション
              </li>
              <li class="text-[#515C66] px-3 py-2 rounded-md text-md font-medium">選ばれる理由</li>
              <li class="text-[#515C66] px-3 py-2 rounded-md text-md font-medium">ユーザーの声</li>
              <li class="text-[#515C66] px-3 py-2 rounded-md text-md font-medium">
                運用開始までの流れ
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const colorTop = ref('');
const colorVoice = ref('');
const colorChoose = ref('');
const colorInquiry = ref('');
const colorSolution = ref('');
const colorCustomerIssues = ref('');
const colorCompatible = ref('');
const route = useRoute();
let colorVariables = ref([]);

const calculatePosition = (sectionId, isClick = false) => {
  const section = document.getElementById(sectionId);
  let top = 0;
  if (section) {
    top = Math.floor(section.getBoundingClientRect().top + window.pageYOffset);
    if (sectionId !== 'topSection') {
      top = top - 110;
    }
    if (isClick) {
      // return position change color when click menu
      return top;
    }
    // return position change color when scroll
    return top - 150;
  }
};

const eventClickToElement = (sectionId) => {
  const top = calculatePosition(sectionId, true);
  window.scrollTo({ top: top, behavior: 'smooth' });
};

const top = () => eventClickToElement('topSection');
const customer_issues = () => eventClickToElement('customerIssuesSection');
const solution = () => eventClickToElement('solutionSection');
const compatible = () => eventClickToElement('compatibleSection');
const us_choose = () => eventClickToElement('usChooseSection');
const voice = () => eventClickToElement('voiceSection');
const inquiry = () => eventClickToElement('inquirySection');
const checkRouter = () => {
  return route.path === '/';
};
onMounted(() => {
  let scrollPositions = [];
  let isScrolling = '';
  colorTop.value = '#e75313';
  document.onreadystatechange = () => {
    if (document.readyState == 'complete') {
      colorVariables = ref([
        { scroll: () => calculatePosition('topSection'), color: colorTop },
        {
          scroll: () => calculatePosition('customerIssuesSection'),
          color: colorCustomerIssues,
        },
        { scroll: () => calculatePosition('solutionSection'), color: colorSolution },
        { scroll: () => calculatePosition('compatibleSection'), color: colorCompatible },
        { scroll: () => calculatePosition('usChooseSection'), color: colorChoose },
        { scroll: () => calculatePosition('voiceSection'), color: colorVoice },
        { scroll: () => calculatePosition('inquirySection'), color: colorInquiry },
      ]);
      // Store the scroll positions in an array
      scrollPositions = colorVariables.value.map((variable) => variable.scroll());
      changeColorElement();
    }
  };
  const changeColorElement = () => {
    for (let variable of colorVariables.value) {
      variable.color = '';
    }
    for (let i = 0; i < colorVariables.value.length; i++) {
      if (window.scrollY >= scrollPositions[i] && window.scrollY < scrollPositions[i + 1]) {
        colorVariables.value[i].color = '#e75313';
        break;
      } else if (
        i === colorVariables.value.length - 1 &&
        window.scrollY >= scrollPositions[scrollPositions.length - 1]
      ) {
        colorVariables.value[i].color = '#e75313';
      }
    }
  };
  window.addEventListener(
    'scroll',
    () => {
      clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        changeColorElement();
      }, 100);
    },
    false,
  );
});
</script>

<style scoped>
li {
  cursor: pointer;
}
</style>
