import router from './router/index';
import 'budoux/module/webcomponents/budoux-ja';
import VueGtag from 'vue-gtag';
import { createApp } from 'vue';
import App from './App.vue';
import font from './assets/css/font.css';
const createMyApp = createApp(App);

createMyApp.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_MEASUREMENT_ID,
    params: {
      send_page_view: false,
    },
  },
});
createMyApp.use(font);
createMyApp.use(router);
router.isReady().then(() => {
  createMyApp.mount('#app');
});
