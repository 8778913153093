<template>
  <div>
    <div class="hidden min-[1281px]:block">
      <div class="footer-container">
        <div class="text">
          <a href="https://www.lixil.co.jp/termsofuse/" class="flex">
            <span class="text-[#FFFFFF] custom-link flex leading-6" style="font-size: 16px"
              >サイト利用条件
            </span>
            <img class="ml-1 block" src="@/assets/images/redirect.svg" alt="" />
          </a>
          <span style="font-size: 12px; color: #d6d8da" class="leading-6 pt-1"
            >Copyright © LIXIL Corporation. All rights reserved.</span
          >
        </div>
        <img class="icon-lixil" src="@/assets/icons/lixil.png" alt="" />
      </div>
    </div>
    <div>
      <div
        class="block min-[1281px]:hidden flex flex-col justify-center items-center bg-[#414950] p-10"
      >
        <img class="w-[100px] mb-8" src="@/assets/icons/lixil.png" alt="lixil" />
        <a href="https://www.lixil.co.jp/termsofuse/">
          <div class="text-white flex font-medium" style="font-size: 16px">
            <span>サイト利用条件</span>
            <img src="@/assets/images/redirect.svg" alt="redirect" />
          </div>
        </a>
        <span style="font-size: 12px; color: #d6d8da"
          >Copyright © LIXIL Corporation. All rights reserved.</span
        >
      </div>
    </div>
  </div>
</template>
<style scoped>
.footer-container {
  margin: 0 auto;
  width: auto;
  padding: 3rem 100px;
  background-color: #414950;
  display: flex;
}
.icon-lixil {
  width: 160px;
  padding: 24px;
  margin-left: auto;
}
.text-color {
  color: #ffffff;
}
.text {
  margin-top: 20px;
}
.custom-link {
  cursor: pointer;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  text-decoration-color: #ffffff;
}
</style>
