import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/menu',
    redirect: '/',
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../pages/terms/Terms.vue'),
  },
  {
    path: '/terms/appendix',
    name: 'AppendixTerm',
    component: () => import('../pages/terms/Appendix.vue'),
  },
  {
    path: '/terms/basic',
    name: 'BasicTerm',
    component: () => import('../pages/basic/Basic.vue'),
  },
  {
    path: '/terms/pluscare',
    name: 'PluscareTerm',
    component: () => import('../pages/pluscare/Pluscare.vue'),
  },
  {
    path: '/terms/pluscare/appendix',
    name: 'AppendixPluscareTerm',
    component: () => import('../pages/pluscare/Appendix.vue'),
  },
  {
    path: '/',
    name: 'landing',
    component: () => import('../pages/menu/index.vue'),
  },
  {
    path: '/oss',
    name: 'oss',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return {
      left: 0,
      top: 0,
    };
  },
});

export default router;
