<template>
  <div id="app">
    <navbar />
    <div>
      <router-view />
    </div>
    <foo-ter />
  </div>
</template>
<script setup>
import navbar from '@/component/Navbar.vue';
import fooTer from '@/component/Footer.vue';
import { useRoute } from 'vue-router';

const route = useRoute();
if (route.path === '/oss') {
  window.open(`${process.env.VUE_APP_ENV}/license-information`, '_self');
}
</script>

<style>
#app {
  font-family: 'Noto Sans CJK JP';
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
  font-weight: 200 !important;
}
</style>
